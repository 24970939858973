export default {
    pages: {
        index: 'Pages',
        title: 'Titre',
        html: 'Contenu HTML',
        view: 'Voir une page <span class="text-caption text-medium-emphasis">{title}</span>',
        edit: 'Modifier une page <span class="text-caption text-medium-emphasis">{title}</span>',
        toaster: {
            PUT: {
                success: {
                    text: 'La page {title} a bien été mise à jour',
                    title: 'Pages',
                },
            },
        },
    },
    demandesacces: {
        application: {
            title: 'Application',
        },
        credentials: {
            created_at: 'Date de la demande',
        },
        custom_column: 'Gestion des droits',
        index: 'Liste des demandes d\'accès',
        response: {
            deny: 'Aucun accès',
            manage: 'Gestion',
            read: 'Lecture',
            write: 'Écriture',
        },
        text: 'Motivation',
        toaster: {
            PUT: {
                success: {
                    text: 'La demande d\'accès a été traitée',
                    title: 'Demande d\'accès',
                },
            },
        },
        user: {
            name: 'Utilisateur',
        },
    },
    structures: {
        add: 'Créer une structure',
        communes: {
            insee: 'Commune',
        },
        communes_count: 'Communes',
        delete: {
            index: 'Supprimer une structure',
            text: 'Souhaitez-vous vraiment supprimer la structure "{name}" ? <br/> <ul class="pl-2 pt-2"> <li>La suppression est <b>irrévocable</b>.</li><li><b>Toutes les données</b> renseignées dans les applications seront supprimées.',
            title: 'Supprimer la structure <span class="text-caption text-medium-emphasis">{name}</span>',
        },
        id: 'id',
        edit: 'Modifier la structure <span class="text-caption text-medium-emphasis">{name}</span>',
        index: 'Liste des structures',
        name: 'Nom',
        toaster: {
            POST: {
                success: {
                    text: 'La structure {name} a bien été créé',
                    title: 'Structures',
                },
            },
            PUT: {
                success: {
                    text: 'La structure {name} a bien été mise à jour',
                    title: 'Structures',
                },
            },
            DELETE: {
                success: {
                    text: 'La structure a bien été supprimée',
                    title: 'Structure supprimée',
                },
            },
        },
        type: {
            AUTRE: 'Autre',
            COMMUNE: 'Commune',
            DEPARTEMENT: 'Département',
            EPCI: 'EPCI',
            PRIVE: 'Privé',
            SYNDICAT: 'Syndicat',
            index: 'Type',
        },
        users: {
            id: 'Utilisateur',
        },
        users_count: 'Utilisateurs',

        view: 'Visualiser la structure <span class="text-caption text-medium-emphasis">{name}</span>',
    },
    utilisateurs: {
        add: 'Ajouter un utilisateur',
        application_roles_count: 'Applications',
        having_application_access: 'Application',
        credentials: {
            created_at: 'Compte créé le',
            updated_by: {
                name: 'Modifié par',
            },
        },
        delete: {
            index: 'Supprimer l\'utilisateur',
            text: 'Souhaitez-vous vraiment supprimer l\'utilisateur "{name}" ? <br/> <ul class="pl-2 pt-2"> <li>La suppression est <b>irrévocable</b>.</li><li><b>Toutes les données</b> renseignées par l\'utilisateur dans les applications seront anonymisées.',
            title: 'Supprimer un utilisateur <span class="text-caption text-medium-emphasis">{name}</span>',
        },
        edit: 'Modifier un utilisateur <span class="text-caption text-medium-emphasis">{first_name} {last_name}</span>',

        export: 'Exporter les utilisateurs',
        has_trashed: {
            all: 'Tous les utilisateurs',
            false: 'Exclure les désactivés',
            index: 'Statut du compte',
            true: 'Seulement les désactivés',
        },
        index: 'Liste des utilisateurs',
        last_connexion: 'Dernière connexion',
        name: 'Nom',
        restore: 'Activer',
        toaster: {
            DELETE: {
                success: {
                    text: 'L\'utilisateur {name} a bien été désactivé',
                    title: 'Utilisateur désactivé',
                },
            },
            POST: {
                success: {
                    text: 'L\'utilisateur {name} a bien été créé',
                    title: 'Utilisateur sauvegardé',
                },
            },
            PUT: {
                success: {
                    text: 'L\'utilisateur {name} a bien été mis à jour',
                    title: 'Utilisateur sauvegardé',
                },
            },
        },
        view: 'Visualiser un utilisateur <span class="text-caption text-medium-emphasis">{first_name} {last_name}</span>',
        managed_organizations: 'Structures gérées',
        can_manage_all: 'Toutes les structures',
        updated_at: 'Date de modification',
    },
    formulaire: 'Formulaire',
    applications: {
        index: 'Applications',
        edit: 'Modifier l\'application',
        add: 'Ajouter une application',
        custom_column: 'Actions',
        title: 'Titre',
        image: 'Image mise en avant',
        icon: 'Icône de l\'application',
        users_count: 'Utilisateurs',
        formulaire: {
            index: 'Modification de l\'application',
            nouveau: 'Création d\'application',
        },
        users: {
            id: 'Utilisateurs',
        },
        description: 'Description',
        html: 'Texte d\'accueil',
        slug: 'Identifiant unique d\'URL',
        show_in_portal: {
            index: 'Afficher dans Portail',
            true: 'Oui',
            false: 'Non',
            all: 'Toutes les applications',
        },
        access_requests_count: {
            index: 'Demandes en attente',
            true: 'Oui',
            false: 'Non',
            all: 'Toutes les demandes',
        },
        delete: {
            index: 'Supprimer l\'application',
            text: 'Souhaitez-vous vraiment supprimer l\'application "{title}" ? <br/> <ul class="pl-2 pt-2"> <li>La suppression est <b>irrévocable</b>.</li><li><b>Toutes les demandes d\'accès</b> seront <b>supprimées</b>.<li><b>Toutes les données</b>renseignées dans l\'application seront <b>supprimées</b>.',
            title: 'Supprimer une application <span class="text-caption text-medium-emphasis">{title}</span>',
        },
        last_connection: 'Dernière connexion',
        name: 'Préfixe des tables dans la base de donnée',
        order: 'Ordre',
        status: {
            index: 'Status',
            draft: 'Brouillon',
            down: 'Désactivée',
            up: 'Publiée',
            archived: 'Archivée',
        },
        toaster: {
            PUT: {
                success: {
                    text: 'L\'application {title} a bien été mise à jour',
                    title: 'Modification de l\'application sauvegardée',
                },
            },
            POST: {
                success: {
                    text: 'L\'application {title} a bien été mise à jour',
                    title: 'Modification de l\'application sauvegardée',
                },
            },
            DELETE: {
                success: {
                    text: 'L\'application {title} a été supprimée',
                    title: 'Suppression de l\'application : {title}',
                },
            },
        },
    },
    applicationTables: {
        index: 'Configuration',
        slug: 'Identifiant unique d\'URL',
        delete: {
            index: 'Supprimer la configuration de la table de l\'application',
            text: 'Souhaitez-vous vraiment supprimer la configuration de la table de l\'application "{title}" ? <br/> <ul class="pl-2 pt-2"> <li>La suppression est <b>irrévocable</b>.</li><li><b>Toutes les demandes d\'accès</b> seront <b>supprimées</b>.<li><b>Toutes les données</b>renseignées dans l\'application seront <b>supprimées</b>.',
            title: 'Supprimer une configuration de la table de l\'application <span class="text-caption text-medium-emphasis">{title}</span>',
        },
        name: 'Nom de la table dans la base de donnée',
        title: 'Titre de la table dans l\'application',
        order: 'Ordre',
        geometry_subtype: {
            index: 'Type de géométrie',
            LineString: 'Ligne',
            Point: 'Point',
            Polygon: 'Polygon',
        },
        is_multiple: 'Multiple',
        toaster: {
            PUT: {
                success: {
                    text: 'La table d\'application {name} a été mise à jour',
                    title: 'Modification de la table d\'application',
                },
            },
            POST: {
                success: {
                    text: 'La table d\'application {name} a été créée',
                    title: 'Création de la table d\'application',
                },
            },
            DELETE: {
                success: {
                    text: 'La table d\'application {name} a été supprimée',
                    title: 'Suppression de la table d\'application',
                },
            },
        },
        SchemaCreate: 'Table à créer',
    },
    applicationTableSections: {
        index: 'Section',
        delete: {
            index: 'Supprimer la section de la table de l\'application',
            text: 'Souhaitez-vous vraiment supprimer la section configuration de la table de l\'application "{title}" ? <br/> <ul class="pl-2 pt-2"> <li>La suppression est <b>irrévocable</b>.</li><li><b>Toutes les demandes d\'accès</b> seront <b>supprimées</b>.<li><b>Toutes les données</b>renseignées dans l\'application seront <b>supprimées</b>.',
            title: 'Supprimer une section de la table de l\'application <span class="text-caption text-medium-emphasis">{title}</span>',
        },
        title: 'Section',
        order: 'Ordre',
        toaster: {
            PUT: {
                success: {
                    text: 'La section table d\'application {name} a été mise à jour',
                    title: 'Modification de la table d\'application',
                },
            },
            POST: {
                success: {
                    text: 'La section de la table d\'application {name} a été créée',
                    title: 'Création de la section de la table d\'application',
                },
            },
            DELETE: {
                success: {
                    text: 'La section de la table d\'application {name} a été supprimée',
                    title: 'Suppression de la section de la table d\'application',
                },
            },
        },
    },
    applicationTableFields: {
        index: 'Champs',
        title: 'Nom',
        name: 'Nom de champ',
        order: 'Ordre',
        field_type: {
            index: 'Type',
            SimpleText: 'Texte',
            CheckBox: 'Choix multiple',
            Select: 'Liste déroulante',
        },
        description: 'Description additionnelle',
        rules: {
            required: 'Champ obligatoire',
            min: 'Longueur minimale',
            max: 'Longueur maximale',
        },
        rules_min: 'Longueur minimale',
        rules_max: 'Longueur maximale',
        rules_required: 'Champ obligatoire',
        display_in_index: 'Afficher dans la liste',
        display_filter: 'Filtrable',
        toaster: {
            PUT: {
                success: {
                    text: 'La section table d\'application {name} a été mise à jour',
                    title: 'Modification de la table d\'application',
                },
            },
            POST: {
                success: {
                    text: 'La section de la table d\'application {name} a été créée',
                    title: 'Création de la section de la table d\'application',
                },
            },
            DELETE: {
                success: {
                    text: 'La section de la table d\'application {name} a été supprimée',
                    title: 'Suppression de la section de la table d\'application',
                },
            },
        },
    },
    couchescartographiques: {
        index: 'Liste des couches cartographiques',
        add: 'Ajouter une couche cartographique',
        view: 'Voir une couche cartographique {layer_type}',
        edit: 'Modifier une couche cartographique {layer_type}',
        delete: {
            index: 'Supprimer une couche cartographique',
            text: 'Souhaitez-vous vraiment supprimer la couche "{name}" ?',
            title: 'Supprimer la couche <span class="text-caption text-medium-emphasis">{name}</span>',
        },
        layer_type: {
            index: 'Type de couche',
            xyz: 'xyz',
            MapServiceLayer: 'Service cartographique',
        },
        name: 'Nom',
        title: 'Titre',
        abstract: 'Description',
        url: 'Url',
        legend_url: 'Légende URL',
        legend_html: 'Légende HTML',
    },
    servicescartographiques: {
        index: 'Liste des services cartographiques',
        add: 'Ajouter un service cartographique',
        view: 'Voir un service cartographique {service_type}',
        edit: 'Modifier un service cartographique {service_type}',
        delete: {
            index: 'Supprimer un service',
            text: 'Souhaitez-vous vraiment supprimer le service "{name}" ?',
            title: 'Supprimer le service <span class="text-caption text-medium-emphasis">{name}</span>',
        },
        service_type: {
            index: 'Type de service',
            WMS: 'WMS',
            WMTS: 'WMTS',
        },
        name: 'Nom',
        title: 'Titre',
        abstract: 'Description',
        url: 'Url',
        activesLayersCount: 'Nombre de couches',
        layers: {
            index: 'Couches cartographiques',
            title: 'Titre',
            name: 'Nom',
            abstract: 'Description',
        },
        toaster: {
            DELETE: {
                success: {
                    text: 'Le service {name} a bien été supprimé',
                    title: 'Service supprimé',
                },
            },
            POST: {
                success: {
                    text: 'Le service {name} a bien été créé',
                    title: 'Service sauvegardé',
                },
            },
            PUT: {
                success: {
                    text: 'Le service {name} a bien été mise à jour',
                    title: 'Service sauvegardé',
                },
            },
        },
    },
    availableslayers: 'Liste des couches cartographiques',
}
